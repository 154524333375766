import * as React from 'react';

import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Container from '../components/Container';
import Page from '../components/Page';
import IndexLayout from '../layouts';
import {
  formatExchangeName,
  PriceChangePercent,
  StyledExchangeCell,
  StyledExchangeCellHeader,
  StyledMoneyCell,
  StyledMoneyCellHeader,
  StyledMoneyPercentHeader,
  StyledPriceCell,
  StyledPriceCellHeader,
  StyledPriceChangeCell,
  StyledTickerNameCell,
  StyleLastUpdatedBlock,
  TickerSymbolAndName,
  TrendsTable
} from '../components/TrendsTable';
import { abbreviateNumber, addCommas } from '../utils/numbers';
import { PageHeader } from '../components/PageHeader';
import Tables from '../components/Tables';

interface Props {
  pageContext: any;
}

const StyledParagraphBetweenTables = styled.p`
  margin-top: -20px;
`;

const StyledVolumeCell = styled(Tables.Cell)`
  text-align: right;
  font-size: 0.9em;
`;

function ScreenerTable2({ items }) {
  return (
    <Tables.Table>
      <Tables.Head>
        <tr>
          <Tables.HeadCell title="The ticker symbol and company name">Symbol (Name)</Tables.HeadCell>
          <StyledExchangeCellHeader title="The exchange the ticker trades on">&nbsp;</StyledExchangeCellHeader>
          <Tables.HeadCell title="The volume on the last trading day">Volume</Tables.HeadCell>
          <Tables.HeadCell title="The Average volume over the last 5 days">Avg. Vol.</Tables.HeadCell>
          <Tables.HeadCell title="EMA with a period 5">EMA5</Tables.HeadCell>
          <Tables.HeadCell title="EMA with a period 12">EMA12</Tables.HeadCell>
          <Tables.HeadCell title="EMA with a period 34">EMA34</Tables.HeadCell>
          <Tables.HeadCell title="RSI with a period 4">RSI</Tables.HeadCell>
          <StyledPriceCellHeader title="The last closing price">Price</StyledPriceCellHeader>
          <StyledMoneyPercentHeader title="The change between the closing price of Yesterday and Today">
            Change (%)
          </StyledMoneyPercentHeader>
        </tr>
      </Tables.Head>
      <Tables.Body>
        {items.length > 0 &&
          items.map(item => (
            <tr key={item.symbol}>
              <StyledTickerNameCell title={item.description}>
                <TickerSymbolAndName ticker={item} />
              </StyledTickerNameCell>
              <StyledExchangeCell title={`${item.exchangeName} (${item.exchange})`}>
                {formatExchangeName(item.exchangeName)}
              </StyledExchangeCell>
              <StyledVolumeCell>{addCommas(item.volume)}</StyledVolumeCell>
              <StyledVolumeCell>{addCommas(item.averageVolume5 | 0)}</StyledVolumeCell>
              <StyledVolumeCell>{item.ema5.toFixed(2)}</StyledVolumeCell>
              <StyledVolumeCell>{item.ema12.toFixed(2)}</StyledVolumeCell>
              <StyledVolumeCell>{item.ema34.toFixed(2)}</StyledVolumeCell>
              <StyledVolumeCell>{item.rsi.toFixed(2)}</StyledVolumeCell>
              <StyledPriceCell>$ {item.price.toFixed(2)}</StyledPriceCell>
              <StyledPriceChangeCell>
                <PriceChangePercent value={item.price} prev={item.previousClosingPrice || ''} />
              </StyledPriceChangeCell>
            </tr>
          ))}
        {items.length === 0 && (
          <tr>
            <Tables.Cell colSpan={10}>0 results</Tables.Cell>
          </tr>
        )}
      </Tables.Body>
    </Tables.Table>
  );
}

function ScreenerTable({ items }) {
  return (
    <Tables.Table>
      <Tables.Head>
        <tr>
          <Tables.HeadCell title="The ticker symbol and company name">Symbol (Name)</Tables.HeadCell>
          <StyledExchangeCellHeader title="The exchange the ticker trades on">&nbsp;</StyledExchangeCellHeader>
          <Tables.HeadCell title="The volume on the last trading day">Volume</Tables.HeadCell>
          <Tables.HeadCell title="The Average volume over the last 20 days">Avg. Volume</Tables.HeadCell>
          <Tables.HeadCell title="EMA with a period 20">EMA20</Tables.HeadCell>
          <StyledPriceCellHeader title="The last closing price">Price</StyledPriceCellHeader>
          <StyledMoneyPercentHeader title="The change between the closing price of Yesterday and Today">
            Change (%)
          </StyledMoneyPercentHeader>
          <StyledMoneyCellHeader title="The market cap">Mrkt Cap.</StyledMoneyCellHeader>
        </tr>
      </Tables.Head>
      <Tables.Body>
        {items.length > 0 &&
          items.map(item => (
            <tr key={item.symbol}>
              <StyledTickerNameCell title={item.description}>
                <TickerSymbolAndName ticker={item} />
              </StyledTickerNameCell>
              <StyledExchangeCell title={`${item.exchangeName} (${item.exchange})`}>
                {formatExchangeName(item.exchangeName)}
              </StyledExchangeCell>
              <StyledVolumeCell>{addCommas(item.volume)}</StyledVolumeCell>
              <StyledVolumeCell>{addCommas(item.averageVolume20 | 0)}</StyledVolumeCell>
              <StyledVolumeCell>{item.ema20.toFixed(2)}</StyledVolumeCell>
              <StyledPriceCell>$ {item.price.toFixed(2)}</StyledPriceCell>
              <StyledPriceChangeCell>
                <PriceChangePercent value={item.price} prev={item.previousClosingPrice || ''} />
              </StyledPriceChangeCell>
              <StyledMoneyCell>{item.marketCap && <>$ {item.marketCap}</>}</StyledMoneyCell>
            </tr>
          ))}
        {items.length === 0 && (
          <tr>
            <Tables.Cell colSpan={8}>0 results</Tables.Cell>
          </tr>
        )}
      </Tables.Body>
    </Tables.Table>
  );
}

const StyledMatchNumber = styled.span`
  color: ${p => p.theme.colors.success};
`;

export default function PennyTemplate({
  pageContext: { pennies, penniesRh, screened, screened2, canadaPennies, wsPennies, dailyPennies, dailyPenniesRh, renderTime }
}: Props) {
  function MatchNumber({ value }) {
    if (!value || value < 1) return <>0 matches</>;
    return (
      <StyledMatchNumber>
        {addCommas(value)} match{value > 1 ? 'es' : ''}
      </StyledMatchNumber>
    );
  }

  return (
    <IndexLayout>
      <Page>
        <Container>
          <Helmet title="wsbdaily - Where you find Tomorrow’s Hot Penny Stocks Yesterday!" />
          <PageHeader time={renderTime} />
          <h1>Pennystock Reddit Trends</h1>
          <p>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/pennystocks">pennystocks</a> community, of{' '}
            {abbreviateNumber(pennies.totalSubscribers)} members, over the last {(pennies.days || 1) * 24}-hours. See our{' '}
            <Link to="/">homepage</Link> for all other market trends.
          </p>
          <TrendsTable data={pennies} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/RobinHoodPennyStocks">RobinHoodPennyStocks</a>{' '}
            community, of {abbreviateNumber(penniesRh.totalSubscribers)} members, over the last {(penniesRh.days || 1) * 24}-hours.
          </StyledParagraphBetweenTables>
          <TrendsTable data={penniesRh} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Canadapennystocks">CanadaPennystocks</a> community, of{' '}
            {abbreviateNumber(canadaPennies.totalSubscribers)} members, over the last {(canadaPennies.days || 1) * 24}-hours. See our{' '}
            <Link to="/canada/">Canadian</Link> homepage for all the Canadian market trends.
          </StyledParagraphBetweenTables>
          <TrendsTable data={canadaPennies} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Wealthsimple_Penny">Wealthsimple_Penny</a> community,
            of {abbreviateNumber(wsPennies.totalSubscribers)} members, over the last {wsPennies.days || 1}-days. See our{' '}
            <Link to="/canada/">Canadian</Link> homepage for all the Canadian market trends.
          </StyledParagraphBetweenTables>
          <TrendsTable data={wsPennies} postsInsteadOfComments />
          <TrendsTable heading="Daily Trends" data={dailyPennies} />
          <p style={{ marginTop: '-20px' }}> </p>
          <TrendsTable data={dailyPenniesRh} />
          {!!screened2 && (
            <>
              <h1>Scanner #1</h1>
              <p>
                An experimental scanner based on the configuration from these threads on the{' '}
                <a href="https://www.reddit.com/r/pennystocks">pennystocks</a> subreddit by{' '}
                <a href="https://www.reddit.com/user/alexsmith2332">/u/alexsmith2332</a>.
              </p>
              <ul>
                <li>
                  <a href="https://old.reddit.com/r/pennystocks/comments/luw1f9/my_updated_stock_scanner_settings_to_get_better/">
                    My updated stock scanner settings to get better results and setups
                  </a>
                </li>
              </ul>
              <p>The scanner is currently using the following criteria:</p>
              <ul>
                <li>
                  <b>Price</b> between <b>$ 0.20 - $ 10.00</b> (
                  <MatchNumber value={screened2.numMatchesPrice || screened2.numMatchesPriceAndVolume} />)
                </li>
                <li>
                  <b>Gain &gt;2%</b> and <b>Gain &lt;50%</b> from previous closing (<MatchNumber value={screened2.numMatchesGains} />)
                </li>
                <li>
                  <b>Volume &gt;= 1.5x Average 5-day volume</b> (<MatchNumber value={screened2.numMatchesVolumeAvg5} />)
                </li>
                <li>
                  <b>EMA(12) &gt;= EMA(34)</b> (<MatchNumber value={screened2.numMatchesEma12GreaterEma34} />)
                </li>
                <li>
                  <b>Closing price &gt;= EMA(12)</b> (<MatchNumber value={screened2.numMatchesCloseGreaterEma12} />)
                </li>
                <li>
                  <b>EMA(5) &gt;= EMA(12)</b> (<MatchNumber value={screened2.numMatchesEma5GreaterEma12} />)
                </li>
                <li>
                  <b>RSI &gt;= 50</b> (<MatchNumber value={screened2.numMatchesRsi} />){' '}
                  <i style={{ opacity: 0.5 }}>* note: standard RSI instead of Williams at the moment</i>
                </li>
              </ul>
              <p>Stocks found matching the scanner criteria on US exchanges:</p>
              <ScreenerTable2 items={screened2.items.filter(i => !i.exchangeName.startsWith('Toronto'))} />
              <StyleLastUpdatedBlock>Last Updated: {screened2.lastUpdatedEst}</StyleLastUpdatedBlock>
              <StyledParagraphBetweenTables>Stocks found matching the scanner criteria on Canadian exchanges:</StyledParagraphBetweenTables>
              <ScreenerTable2 items={screened2.items.filter(i => i.exchange === 'TOR' || i.exchangeName.startsWith('Other'))} />
              <StyleLastUpdatedBlock>Last Updated: {screened.lastUpdatedEst}</StyleLastUpdatedBlock>
            </>
          )}
          {!!screened && screened.items.length > 0 && (
            <>
              <h1>Scanner #2</h1>
              <p>
                An experimental scanner based on the configuration from these threads on the{' '}
                <a href="https://www.reddit.com/r/pennystocks">pennystocks</a> subreddit by{' '}
                <a href="https://www.reddit.com/user/alexsmith2332">/u/alexsmith2332</a>.
              </p>
              <ul>
                <li>
                  <a href="https://www.reddit.com/r/pennystocks/comments/l36uwg/a_scanner_which_has_got_me_a_pretty_good_success/">
                    A scanner which has got me a pretty good success rate (~85% success rate)
                  </a>
                </li>
                <li>
                  <a href="https://www.reddit.com/r/pennystocks/comments/lbgs07/how_to_filter_stocks_from_your_scanner_to_choose/">
                    How to filter stocks from your scanner to choose which ones to invest in.
                  </a>
                </li>
              </ul>
              <p>The scanner is currently using the following criteria:</p>
              <ul>
                <li>
                  <b>Price</b> between <b>$ 0.20 - $ 10.00</b>
                </li>
                <li>
                  <b>Gain &gt;2%</b> from previous closing
                </li>
                <li>
                  <b>Price &gt;= EMA20</b> (closes <b>&gt; 5%</b> above 20 period EMA)
                </li>
                <li>
                  <b>Rel. Volume &gt;= 3x Average Volume</b> (avg. over 20 days)
                </li>
              </ul>
              <p>Stocks found matching the scanner criteria on US exchanges:</p>
              <ScreenerTable items={screened.items.filter(i => !i.exchangeName.startsWith('Toronto'))} />
              <StyleLastUpdatedBlock>Last Updated: {screened.lastUpdatedEst}</StyleLastUpdatedBlock>
              <StyledParagraphBetweenTables>Stocks found matching the scanner criteria on Canadian exchanges:</StyledParagraphBetweenTables>
              <ScreenerTable items={screened.items.filter(i => i.exchange === 'TOR' || i.exchangeName.startsWith('Other'))} />
              <StyleLastUpdatedBlock>Last Updated: {screened.lastUpdatedEst}</StyleLastUpdatedBlock>
            </>
          )}
        </Container>
      </Page>
    </IndexLayout>
  );
}
